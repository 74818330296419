import CreateAccount from "../../assets/img/icons/create-account.png";
import BankAccount from "../../assets/img/icons/bank-account.png";
import Coins from "../../assets/img/icons/coins.png";

export const howItWorks = [
	{
		name: "Create Account",
		description:
			"Sign up with us today. Create your own Kamivest account to start saving and investing.",
		icon: { image: CreateAccount },
	},
	{
		name: "Add Your Bank Account",
		description:
			"Set up your first plan. Add your debit card, bank account, USSD or QR Code to get started.",
		icon: { image: BankAccount },
	},
	{
		name: "Save and Invest",
		description:
			"Fund your account to start saving and earning returns from your investments.",
		icon: { image: Coins },
	},
];
