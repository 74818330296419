import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import { CTA } from "../../components/CTA/CTA";
import Footer from "../../components/Footer/Footer";

import ServicesTrading from "../../assets/img/mockups/services-trading.jpg";
import AdvisoryImg from "../../assets/img/mockups/advisory-hero.jpg";
import BarChart from "../../assets/img/icons/bar-chart.png";
import DollarBag from "../../assets/img/icons/dollar-bag.png";
import BalanceScale from "../../assets/img/icons/balance-scale.png";

const About = () => {
	return (
		<>
			<Navbar />

			{/* Section */}
			<div className='relative bg-white pt-16 pb-32 overflow-hidden'>
				<div className='relative' id='trading'>
					<div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
						<div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
							<div>
								<img
									src={BarChart}
									className='h-12 w-12 rounded-md flex items-center justify-center'
									alt='Trading'
								/>
								<div className='mt-6'>
									<h2 className='text-3xl font-extrabold tracking-tight text-green-500'>
										Trading
									</h2>
									<p className='mt-4 text-lg text-gray-500'>
										Trading on our platform gives you easy access to the global
										stock markets. Our platform has been designed to suit your
										needs and to give you an edge whether you are an active or
										passive trader.
									</p>
									<p className='mt-4 text-lg text-gray-500'>
										At Kamivest , we offer traders and investors powerful tools
										that not only level the playing field but also optimises
										their investment strategy in the competitive world of
										trading and investments.
									</p>
									<p className='mt-4 text-lg text-gray-500'>
										Our experienced portfolio managers can also help you manage
										and grow your funds by investing in our portfolio of
										high-growth stocks across the global market.
									</p>
								</div>
							</div>
						</div>
						<div className='mt-12 sm:mt-16 lg:mt-0'>
							<div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
								<img
									className='w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
									src={ServicesTrading}
									alt='Man checking his stock trading app'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-24 bg-green-50' id='investments'>
					<div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-1 lg:grid-flow-col-dense lg:gap-24'>
						<div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0'>
							<div>
								<img
									src={DollarBag}
									className='h-12 w-12 rounded-md flex items-center justify-center'
									alt='Trading'
								/>
								<div className='mt-6'>
									<h2 className='text-3xl font-extrabold tracking-tight text-green-500'>
										Investments
									</h2>
									<p className='mt-4 text-lg text-gray-500'>
										We offer hedge fund and asset management services. Our
										experienced managers channel funds from our clients into
										alternative investment opportunities that yield competitive
										returns. Equipped with insightful research, our managers
										employ a wide range of strategies to maximize your monies by
										leveraging technology, responding to global economic trends,
										reallocating capital and skillfully managing exposures to
										outlier events.
									</p>
									<p className='mt-4 text-lg text-gray-500'>
										Our managers understand capital markets (local and global),
										the industries we play in (agriculture, innovation and
										emerging technology, real estate etc) and create different
										portfolios to cater to the risk appetite of our corporate
										clients.
									</p>
									<p className='mt-4 text-lg font-bold'>Stocks</p>
									<p className='mt-4 text-lg text-gray-500'>
										We help you invest and manage your money into a portfolio of
										expertly selected US and Global stocks based on their
										long-term growth opportunities and quality earnings.
									</p>
									<p className='mt-4 text-lg font-bold'>Real Estate</p>
									<p className='mt-4 text-lg text-gray-500'>
										Housing is a basic human need which means that real estate
										will always present attractive investment opportunities. Our
										investment under this category is geared towards providing
										our users with returns from rentals and capital appreciation
										gains from carefully selected and vetted real estate
										projects.
									</p>
									<p className='mt-4 text-lg font-bold'>
										Alternative Investments
									</p>
									<p className='mt-4 text-lg text-gray-500'>
										We invest and manage your funds in alternative investments
										that fill the gap within the fintech, disruptive innovation
										and healthcare ecosystem in Nigeria. We may act as a broker
										to bring you profitable investments in Mutual funds, ETFs
										and Treasury bills that yield high profits and quality
										returns or facilitate commodities trading in this sector.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-24' id='advisory'>
					<div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
						<div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
							<div>
								<img
									src={BalanceScale}
									className='h-12 w-12 rounded-md flex items-center justify-center'
									alt='Trading'
								/>
								<div className='mt-6'>
									<h2 className='text-3xl font-extrabold tracking-tight text-green-500'>
										Advisory
									</h2>
									<p className='mt-4 text-lg text-gray-500'>
										We provide digital solutions to meet all your financial
										targets through customized planning or investment management
										led by our highly experienced financial advisors. Our
										considerations range from identifying our client’s current
										capital mix, determining the client’s optimum capital mix
										given the peculiar nature of their operations as well as
										industry, recommending the changes necessary to achieve the
										optimum mix and helping our client in implementing the
										highlighted changes.
									</p>
								</div>
							</div>
						</div>
						<div className='mt-12 sm:mt-16 lg:mt-0'>
							<div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
								<img
									className='w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
									src={AdvisoryImg}
									alt='Man analyzing his stocks'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default About;
