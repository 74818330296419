export const stocks = [
  {
    symbol: "AAPL",
    name: "Apple",
    price: "$3,400. 3",
    percentage: "+2.77% (51.18)",
    isLow: false,
  },
  {
    symbol: "MSFT",
    name: "Microsoft",
    price: "$121. 86",
    percentage: "-0.30% (0.85)",
    isLow: true,
  },
  {
    symbol: "IBM",
    name: "IBM",
    price: "$932. 57",
    percentage: "-0.94% (8.84)",
    isLow: true,
  },
  {
    symbol: "AMZN",
    name: "Amazon.com Inc",
    price: "$3,400. 3",
    percentage: "+2.77% (51.18)",
    isLow: false,
  },
  {
    symbol: "Shop",
    name: "Shopify",
    price: "$1,805. 26",
    percentage: "+0.28% (4.64)",
    isLow: false,
  },
  {
    symbol: "DIA",
    name: "DIA",
    price: "$347.11",
    percentage: "+5.56(+1.63%)",
    isLow: false,
  }
];
