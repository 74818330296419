import Marquee from "react-fast-marquee";

import { stocks } from "../../fixtures/stocks/stocks";

export default function StockTicker() {
  return (
    <div className="bg-white flex">
      <Marquee gradient={false}>
        {stocks.map((stock, index) => (
          <div
            key={index}
            className="border border-gray-4 mr-3 py-2 px-4 flex justify-between  w-60 md:w-64"
          >
            <div>
              <div className="text-xs text-gray-5">{stock.symbol}</div>
              <div className="text-xs text-gray-5">{stock.name}</div>
            </div>
            <div>
              <div
                className={`${
                  stock.isLow ? `text-red-2` : `text-green-3`
                } text-xs font-semibold flex items-center`}
              >
                {stock.isLow ? (
                  <svg
                    className="mr-2"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.50439 6.12305L0.0402933 0.123046L6.9685 0.123047L3.50439 6.12305Z"
                      fill="#EB5757"
                    />
                  </svg>
                ) : (
                  <svg
                    className="mr-2"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.50439 0.123047L6.9685 6.12305H0.040293L3.50439 0.123047Z"
                      fill="#58CBA7"
                    />
                  </svg>
                )}
                $3,400. 36
              </div>
              <div
                className={`${
                  stock.isLow ? `text-red-2` : `text-green-3`
                } font-light text-x flex items-center`}
              >
                +1.18% (39.64)
              </div>
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
}
