import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Chart({ chartData, years, calcResult }) {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    colors: ["#B3FFE8", "#27BBD0", "#10616e"],
    xAxis: {
      categories: years,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      maxPadding: 0.05,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Future Investment",
        data: chartData?.futureInvestment
          ? chartData?.futureInvestment
          : chartData,
      },
      {
        name: "Total Interest Earned",
        data: chartData?.totalInterest ? chartData?.totalInterest : chartData,
      },
      {
        name: "Total Deposits",
        data: chartData?.totalDeposits ? chartData?.totalDeposits : chartData,
      },
    ],
  };
  return (
    <div className={`px-3 md:px-5 pb-2`}>
      <div className="pb-10">
        <span className="text-gray-3 text-sm md:text-base">
          Your returns <br /> in{" "}
          <span className="font-bold">{years?.length || 0}</span> year(s)
        </span>
        {calcResult?.futureInvestment ? (
          <div className="flex justify-around font-medium text-sm mt-5">
            <div>
              <div className="flex">
                <svg width="18" height="18">
                  <rect
                    x="2"
                    y="4"
                    width="12"
                    height="12"
                    fill="#10616e"
                    rx="6"
                    ry="6"
                  ></rect>
                </svg>
                <span className="ml-1"> Total Deposits</span>
              </div>
              <div className="text-center text-base">
                {"\u20A6"}
                {calcResult?.totalDeposits?.toLocaleString() || 0}
              </div>
            </div>

            <div>
              <div className="flex">
                {" "}
                <svg width="18" height="18">
                  <rect
                    x="2"
                    y="4"
                    width="12"
                    height="12"
                    fill="#27BBD0"
                    rx="6"
                    ry="6"
                  ></rect>
                </svg>
                <span className="ml-1"> Total Interest Earned</span>
              </div>
              <div className="text-center text-base">
                {"\u20A6"}
                {calcResult?.totalInterestEarned?.toLocaleString() || 0}
              </div>
            </div>
            <div>
              {" "}
              <div className="flex">
                {" "}
                <svg width="18" height="18">
                  <rect
                    x="2"
                    y="4"
                    width="12"
                    height="12"
                    fill="#B3FFE8"
                    rx="6"
                    ry="6"
                  ></rect>
                </svg>
                <span className="ml-1"> Future Investment</span>
              </div>
              <div className="text-center text-base">
                {"\u20A6"}
                {calcResult?.futureInvestment?.toLocaleString() || 0}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
