import Axios from "axios";
import { config } from "../config/config";

const newsCatcherAxiosInstance = Axios.create();

newsCatcherAxiosInstance.defaults.baseURL = "https://api.newscatcherapi.com/v2";

newsCatcherAxiosInstance.defaults.headers = {
	"Content-Type": "application/json",
	Accept: "application/json",
	"X-API-KEY": `${config.newsCatcherKey}`,
};

//Timeout
newsCatcherAxiosInstance.defaults.timeout = 5000;

//Methods
export function getRequest(URL) {
	return newsCatcherAxiosInstance.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
	return newsCatcherAxiosInstance
		.post(`/${URL}`, payload)
		.then((response) => response);
}

export function patchRequest(URL, payload) {
	return newsCatcherAxiosInstance
		.patch(`/${URL}`, payload)
		.then((response) => response);
}

export function putRequest(URL, payload) {
	return newsCatcherAxiosInstance
		.put(`/${URL}`, payload)
		.then((response) => response);
}

export function deleteRequest(URL) {
	return newsCatcherAxiosInstance
		.delete(`/${URL}`)
		.then((response) => response);
}
