import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar/Navbar.jsx";
import { CTA } from "../../components/CTA/CTA";
import Footer from "../../components/Footer/Footer";
import { Pagination } from "../../components/Pagination/Pagination";
import { Articles } from "../../components/Articles/Articles";

import { getRequest } from "../../api/newsCatcherAxiosInstance";
import { ARTICLES_PER_PAGE } from "../../utils/constants/articlesPerPage.js";

export const News = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [newsData, setNewsData] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		const getData = async () => {
			setIsLoading(true);

			try {
				const res = await getRequest(`latest_headlines?topic=finance&lang=en`);
				setNewsData(res?.data?.articles);
				setTotalPages(
					Math.round(res?.data?.articles.length / ARTICLES_PER_PAGE)
				);
			} catch (error) {
				// console.error("News data::", error);
			} finally {
				setIsLoading(false);
			}
		}; 
		getData();
	}, []);

	const handleActivePage = (pageNumber) => {
		setPage(pageNumber);
	};

	return (
		<>
			<Navbar />
			<div className='relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
				<div className='absolute inset-0'>
					<div className='bg-white h-1/3 sm:h-2/3' />
				</div>
				<div className='relative max-w-7xl mx-auto'>
					<div className='text-center'>
						<h2 className='text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl'>
							News
						</h2>
						<p className='mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4'>
							A curated list of global news from sources across the world.
						</p>
					</div>
					{isLoading && (
						<div className='flex flex-col justify-center items-center mt-24 w-2/5 max-w-lg mx-auto lg:max-w-none'>
							<div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12'></div>
							<h2 className='text-center text-gray-800 text-xl font-semibold mt-2'>
								Loading...
							</h2>
							<p className='w-4/5 text-center text-gray-600 mt-10'>
								This may take a few seconds, please don't close this page.
							</p>
						</div>
					)}
					<Articles newsData={newsData} page={page} />
					{!isLoading && (
						<Pagination
							totalPages={totalPages}
							activePage={handleActivePage}
							page={page}
						/>
					)}
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};
