import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import { LOGO_OPTIONS } from "../../../utils/constants/logo";

import IndexImg from "../../../assets/img/index/index-mockup.png";

import "./Index.css";

export const Index = () => {
	const [buttonLabel, setButtonLabel] = useState("Enter site");

	const history = useHistory();

	const handleHomePageRedirect = () => {
		setTimeout(() => {
			history.push("/home");
		}, 1000);

		setButtonLabel("Processing...");
	};

	return (
		<>
			<div className='bg-green-500 min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 -z-20'>
				<div className='max-w-max mx-auto'>
					<main className='sm:flex'>
						<div className='lg:grid lg:grid-cols-12 lg:gap-8'>
							<div className='sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6'>
								<div className='flex justify-center'>
									<motion.img
										src={LOGO_OPTIONS.white.default}
										alt='Kamivest logo'
										className='d-block mb-10 w-auto h-8 sm:h-10'
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 0.4 }}
									/>
								</div>
								<h1 className='mt-2 text-4xl sm:text-5xl text-white text-center font-extrabold tracking-tight'>
									<motion.span
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 1.3 }}
										className='text-white'
									>
										Invest.
									</motion.span>{" "}
									<motion.span
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 2.1 }}
										className='text-white'
									>
										Technology.
									</motion.span>{" "}
									<br />
									<motion.span
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 2.9 }}
										className='text-white'
									>
										Create wealth.
									</motion.span>
								</h1>
								<motion.p
									className='mt-3 px-10 text-base text-white text-center sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ delay: 3.9 }}
								>
									A dynamic investment and savings platform that gives you
									access to global investment opportunities from the comfort of
									your home.
								</motion.p>
								<div className='mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-center z-40'>
									<motion.button
										type='submit'
										onClick={handleHomePageRedirect}
										className='mt-3 w-full px-6 py-3 border border-white text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 md:ml-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto'
										initial={{ opacity: 0, scale: 1.7 }}
										animate={{ opacity: 1, scale: 1 }}
										transition={{ delay: 3.9 }}
										whileHover={{ scale: 1.5 }}
									>
										{buttonLabel}
									</motion.button>
								</div>
							</div>
							<div className='mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center'>
								<svg
									className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden'
									width={640}
									height={784}
									fill='none'
									viewBox='0 0 640 784'
									aria-hidden='true'
								>
									<defs>
										<pattern
											id='4f4f415c-a0e9-44c2-9601-6ded5a34a13e'
											x={118}
											y={0}
											width={20}
											height={20}
											patternUnits='userSpaceOnUse'
										>
											<rect
												x={0}
												y={0}
												width={4}
												height={4}
												className='text-gray-200'
												fill='currentColor'
											/>
										</pattern>
									</defs>
									<rect
										y={72}
										width={640}
										height={640}
										className='text-gray-50'
										fill='currentColor'
									/>
									<rect
										x={118}
										width={404}
										height={784}
										fill='url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)'
									/>
								</svg>
								<motion.img
									className='w-full h-full relative mx-auto'
									src={IndexImg}
									alt=''
									initial={{ opacity: 0, y: "-100vh" }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ type: "spring", stiffness: 250, delay: 3.9 }}
								/>
							</div>
						</div>
					</main>
					<ul className='circles -z-10'>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	);
};
