import ceoImg from "../../assets/img/about/eni-ceo.jpg";
import cooImg from "../../assets/img/about/tola-coo.jpg";
import croImg from "../../assets/img/about/anthony-cro.jpg";
import iaImg from "../../assets/img/about/kamson-ia.jpg";

export const EXECUTIVES = [
	{
		name: "Eni Eniola",
		role: "Co-founder/Chief Executive Officer",
		position: "CEO",
		imageUrl: { image: ceoImg },
		twitterUrl: "#",
		linkedinUrl: "#",
		profileLink: "/about/team/eni-eniola",
	},
	{
		name: "Adetola Osindero",
		role: "Co-founder/Chief Operating Officer",
		position: "COO",
		imageUrl: { image: cooImg },
		twitterUrl: "#",
		linkedinUrl: "#",
		profileLink: "/about/team/tola-osindero",
	},
	{
		name: "Engr. Abi Kamson",
		role: "Investment Advisor",
		position: "IA",
		imageUrl: { image: iaImg },
		twitterUrl: "#",
		linkedinUrl: "#",
		profileLink: "/about/team/abi-kamson",
	},
	{
		name: "Anthony Tippa",
		role: "Consultant-Risk",
		position: "CRA",
		imageUrl: { image: croImg },
		twitterUrl: "#",
		linkedinUrl: "#",
		profileLink: "/about/team/anthony-tippa",
		academicHonorifics: "FSA MAAA FLMI"
	},
];
