import ColoredLogo from "../../assets/img/logo/kamivest_logo.svg";
import ColoredLogoCenter from "../../assets/img/logo/kamivest_logo_center.svg";
import ColoredLogoCenterWithoutTagline from "../../assets/img/logo/kamivest_logo_default_center.svg";
import ColoredLogoBadge from "../../assets/img/logo/kamivest_icon_badge.svg";
import WhiteLogo from "../../assets/img/logo/kamivest_logo_center_white.svg";

export const LOGO_OPTIONS = {
	colored: {
		default: ColoredLogo,
		center: ColoredLogoCenter,
		withoutTagline: ColoredLogoCenterWithoutTagline,
		badge: ColoredLogoBadge,
	},
	white: {
		default: WhiteLogo,
	},
};
