import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import StockTicker from "../../components/StockTicker/StockTicker";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
	{
		question: "How can I open a Kamiko account?",
		answer:
			"To get started, download the app on google play or app store and register in the app.",
	},
	{
		question: "What happens after opening a Kamiko account?",
		answer:
			"Select any of our products, sit back and watch your investments grow exponentially.",
	},
	{
		question: "Do you accept payments on weekends?",
		answer: "Yes we do.",
	},
	{
		question: "What will be my monthly ROI?",
		answer:
			"Dependent on the product you pick, you can earn up to 24-36% PA for kamiko monthly naira investment, while for Dollar investment, you can earn up to 12% PA.",
	},
	{
		question: "What is the maximum amount for investments?",
		answer: "There is no cap on the amount you wish to invest.",
	},
	{
		question: "How will I be paid my monthly returns?",
		answer:
			"Returns are being paid to your beneficiary account every 25 working days.",
	},
	{
		question: "Why are there extra security measures on my account?",
		answer:
			"To help ensure the safety of your account, we added additional two factor authentication security measures when attempting to log in to your account from a new device. Because of the change, you’re no longer able to verify a new trusted device by email alone. Instead, you’ll need to verify any new trusted devices using the phone number associated with your account.",
		note: "You may see a “Request Throttled” error message if we detect suspicious activity from the IP address you’re using. If you see this error, we recommend switching to another network.",
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const FAQs = () => {
	return (
		<>
			<Navbar />
			<StockTicker/>
			<div className='bg-gray-50'>
				<div className='max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8'>
					<div className='max-w-3xl mx-auto divide-y-2 divide-gray-200'>
						<h2 className='text-center text-3xl font-extrabold text-gray-900 sm:text-4xl'>
							Frequently asked questions
						</h2>
						<dl className='mt-6 space-y-6 divide-y divide-gray-200'>
							{faqs.map((faq) => (
								<Disclosure as='div' key={faq.question} className='pt-6'>
									{({ open }) => (
										<>
											<dt className='text-lg'>
												<Disclosure.Button className='text-left w-full flex justify-between items-start text-gray-400'>
													<span className='font-medium text-gray-900'>
														{faq.question}
													</span>
													<span className='ml-6 h-7 flex items-center'>
														<ChevronDownIcon
															className={classNames(
																open ? "-rotate-180" : "rotate-0",
																"h-6 w-6 transform"
															)}
															aria-hidden='true'
														/>
													</span>
												</Disclosure.Button>
											</dt>
											<Disclosure.Panel as='dd' className='mt-2 pr-12'>
												<p className='text-base text-gray-500'>{faq.answer}</p>
												{faq?.note && (
													<p className='text-sm text-gray-500 mt-4 italic'>
														{faq?.note}
													</p>
												)}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							))}
						</dl>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
