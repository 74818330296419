import whyKamivest from "../../../fixtures/home/whyKamivest.json";

export default function WhyKamivest() {
  return (
    <div className="bg-gray-torq-light py-16 px-4 z-20 w-full">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid gap-3 md:flex md:flex-wrap justify-center w-full">
          {whyKamivest?.map((data, index) => (
            <div
              key={index}
              className="text-gray-2 border rounded-2xl border-gray-6 py-12 px-8 h-auto bg-white w-full md:w-45 lg:w-23 text-center my-3 cursor-pointer hover:bg-torquise-2 hover:text-white"
            >
              <div>
                <div className="text-xl font-roboto font-black	mb-2">
                  {data.title}
                </div>
                <p className="text-sm">{data.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
