import React from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { ProductHero } from "../../../components/Products/ProductHero/ProductHero";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";
import features from "../../../fixtures/products/p2p.json";

const KamikoDIYTrading = () => {
	let location = useLocation();

	const productIndex = PRODUCT_INFO.findIndex(
		(product) => product.learnMore === location.pathname
	);

	return (
		<>
			<Navbar />
			<ProductHero
				image={PRODUCT_INFO[productIndex].image.image}
				title={PRODUCT_INFO[productIndex].name}
				subtitle={PRODUCT_INFO[productIndex].description}
				primaryButton='Join Waitlist'
				secondaryButton='Learn More'
			/>
			<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
				<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
					<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						A reliable way to swap currencies at the best rates.
					</p>
					<p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
						Looking for the safest and easiest way to swap currencies? Kamiko
						P2P solves this problem for you! Leveraging the community we have
						built, we make it easy to swap currencies at your preferred rate
						within the kamivest app.
					</p>
					<div className='mt-12'>
						<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2'>
							{features.map((feature) => (
								<div key={feature.name} className='pt-6'>
									<div className='flow-root bg-gray-50 rounded-lg px-6 py-8'>
										<div className='-mt-6'>
											<h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>
												{feature.name}
											</h3>
											<p className='mt-5 text-base text-gray-500'>
												{feature.subtitle}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default KamikoDIYTrading;
