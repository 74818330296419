import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";
import investmentFeatures from "../../../fixtures/products/pro-growth.json";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { ProductHero } from "../../../components/Products/ProductHero/ProductHero";

const KamikoProGrowth = () => {
	const location = useLocation();
	const history = useHistory();

	const productIndex = PRODUCT_INFO.findIndex(
		(product) => product.learnMore === location.pathname
	);

	const handleCalculatorRedirect = (event) => {
		event.preventDefault();
		history.push("/calculator");
	};

	return (
		<>
			<Navbar />
			<ProductHero
				image={PRODUCT_INFO[productIndex].image.image}
				title={PRODUCT_INFO[productIndex].name}
				subtitle={PRODUCT_INFO[productIndex].description}
				primaryButton='Join Waitlist'
				secondaryButton='Learn More'
			/>
			<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
				<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
					<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						A specially designed investment product that helps wealth
						accumulation through investments in a selection of top performing
						stocks and mutual funds and ETFs.
					</p>
					<p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
						Kamiko Pro-Growth’s primary objective is to manage clients’ real and
						financial assets, with the goal of realizing their investment
						targets.
					</p>
					<div className='mt-12'></div>
					<p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
						Grow your wealth by investing a fixed amount monthly or lump sum in
						Stocks, ETFs and Mutual Funds Invest with as little as $100 per
						month in one or more of the selected funds. Experience Ease and
						flexibility to change investment amount in a minimum of 24 month.
					</p>
					<div className='mt-12'>
						<h1 className='font-bold text-green-600 text-2xl'>
							Investment features
						</h1>
						<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
							{investmentFeatures.map((feature) => (
								<div key={feature.name} className='pt-6'>
									<div className='flow-root bg-gray-50 rounded-lg px-6 py-8'>
										<div className='-mt-6'>
											<h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>
												{feature.name}
											</h3>
											<p className='mt-5 text-base text-gray-500'>
												{feature.subtitle}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className='py-10'>
						<button
							type='button'
							className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none'
							onClick={handleCalculatorRedirect}
						>
							Calculate returns
						</button>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default KamikoProGrowth;
