import React, { useEffect, useState } from "react";
import * as yup from "yup";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Chart from "../../components/Chart/Chart";
import Form from "../../components/Calculator/Form";

import infoIcon from "../../assets/img/icons/info.svg";
import arrowDown from "../../assets/img/icons/arrow-down.svg";

const Calculator = () => {
  const [formData, setFormData] = useState({
    currency: "NGN",
    productName: "",
    initialDeposit: "",
    investmentType: {
      label: "Monthly",
      value: "monthly",
    },
    investmentAmount: "",
    roi: "",
    investmentLength: "",
  });

  const [chartGroup, setChartGroup] = useState({
    futureInvestment: [0],
    totalInterest: [0],
    totalDeposits: [0],
  });

  const [calcResult, setCalcResult] = useState({
    futureInvestment: 0,
    totalDeposits: 0,
    totalInterest: 0,
  });
  const [additionalInvestmentField, setAdditionalInvestmentField] =
    useState(false);
  const [interestTypeMessage, setInterestTypeMessage] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [years, setYears] = useState([]);
  const productValue = formData?.productName?.value;
  const productSlug = formData?.productName?.slug;

  // Form validation
  const formSchema = yup.object().shape({
    currency: yup.string().required(),
    productName: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    investmentLength: yup.number().required(),
    investmentType: yup.object().shape({
      label: yup.string().when("productName", {
        is: (productName) => productName?.value === "compound",
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
      value: yup.string().when("productName", {
        is: (productName) => productName?.value === "compound",
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
    }),

    investmentAmount: yup.string().when("productName", {
      is: (productName) => productName?.value === "compound",
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    initialDeposit: yup.number().required(),
  });

  const [errors, setErrors] = useState({
    currency: false,
    investmentType: false,
    investmentAmount: false,
    investmentLength: false,
    productName: false,
    initialDeposit: false,
  });
  ////////

  useEffect(() => {
    setShowChart(false);
  }, [formData]);

  useEffect(() => {
    if (productValue === "compound") {
      setAdditionalInvestmentField(true);
      setChartGroup({
        futureInvestment: [0],
        totalInterest: [0],
        totalDeposits: [0],
      });
      setCalcResult({
        futureInvestment: 0,
        totalDeposits: 0,
        totalInterest: 0,
      });
    } else if (productValue === "simple") {
      setAdditionalInvestmentField(false);
      setChartGroup({
        futureInvestment: [0],
        totalInterest: [0],
        totalDeposits: [0],
      });
      setCalcResult({
        futureInvestment: 0,
        totalDeposits: 0,
        totalInterest: 0,
      });
    } else {
      setAdditionalInvestmentField(false);
    }
  }, [productValue]);

  // update interest rate
  useEffect(() => {
    if (productSlug === "normal") {
      setFormData({
        ...formData,
        roi: 8,
      });
      setInterestTypeMessage(
        "Low risk investments consists of low dividend stocks,bonds, fixed annuities, treasure bills and corporate bonds."
      );
    } else if (productSlug === "moderate") {
      setFormData({
        ...formData,
        roi: 12,
      });

      setInterestTypeMessage(
        "Moderate investments consist of mutual funds, real estate, municipal bonds and preferred stock options."
      );
    } else if (productSlug === "aggressive") {
      setFormData({
        ...formData,
        roi: 15,
      });
      setInterestTypeMessage(
        "Aggressive investments consist of high yield bonds, foreign exchange, crypto, hedge funds, ETFs abd private company investments."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSlug]);

  // calculate simple interest of the investment
  const calculateSimpleInterest = (investmentAmount, roi, investmentLength) => {
    const totalInterestEarned = Number(
      Number((investmentAmount * roi * investmentLength) / 100).toFixed(2)
    );
    const futureInvestment = totalInterestEarned + investmentAmount;
    return {
      totalInterestEarned,
      futureInvestment,
      totalDeposits: investmentAmount,
    };
  };

  // calculate compound interest of the investment
  const calculateCompoundInterest = (
    investmentType,
    roi,
    investmentAmount,
    investmentLength,
    initialDeposit
  ) => {
    const compoundFrequency = investmentType.value === "monthly" ? 12 : 1;
    const roiDecimal = roi / 100;

    // formula
    // P(1+r/n)^(nt) ] + [ PMT × (((1 + r/n)^(nt) - 1) / (r/n))
    const futureInvestment = Number(
      Number(
        initialDeposit *
          (1 + roiDecimal / compoundFrequency) **
            (compoundFrequency * investmentLength) +
          investmentAmount *
            (((1 + roiDecimal / compoundFrequency) **
              (compoundFrequency * investmentLength) -
              1) /
              (roiDecimal / compoundFrequency))
      ).toFixed(2)
    );

    // P + (PMT x n x t)
    const totalDeposits = Number(
      Number(
        initialDeposit + investmentAmount * compoundFrequency * investmentLength
      ).toFixed(2)
    );

    // futureInvestment - totaldeposits
    const totalInterestEarned = Number(
      Number(futureInvestment - totalDeposits).toFixed(2)
    );

    return {
      futureInvestment,
      totalDeposits,
      totalInterestEarned,
    };
  };

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = max + (formData?.investmentLength - 1);
    let years = [];

    for (var i = max; i <= min; i++) {
      years.push(i);
    }
    return years;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setYears(generateArrayOfYears());
    setShowChart(false);
    const isFormValid = await formSchema.isValid(formData, {
      abortEarly: false,
    });

    if (isFormValid) {
      // update error state
      setErrors({
        currency: false,
        investmentType: false,
        investmentAmount: false,
        investmentLength: false,
        productName: false,
        initialDeposit: false,
      });

      let noOfYears = generateArrayOfYears().length;
      // check interest type
      if (productValue === "simple") {
        const SI = calculateSimpleInterest(
          Number(formData.initialDeposit),
          Number(formData.roi),
          Number(formData.investmentLength)
        );
        setCalcResult(SI);
        // graph logic

        const futureInvestmentArray = () => {
          let arr = [];
          for (var i = 0; i < noOfYears; i++) {
            arr.push(
              Number(
                calculateSimpleInterest(
                  Number(formData.initialDeposit),
                  Number(formData.roi),
                  i + 1
                ).futureInvestment
              )
            );
          }
          return arr;
        };

        const totalInterestArray = () => {
          let arr = [];

          for (var i = 0; i < noOfYears; i++) {
            arr.push(
              Number(
                calculateSimpleInterest(
                  Number(formData.initialDeposit),
                  Number(formData.roi),
                  i + 1
                ).totalInterestEarned
              )
            );
          }
          return arr;
        };

        setChartGroup({
          futureInvestment: futureInvestmentArray(),
          totalInterest: totalInterestArray(),
        });
        setShowChart(true);

        //////
      } else {
        const CI = calculateCompoundInterest(
          formData.investmentType,
          Number(formData.roi),
          Number(formData.investmentAmount),
          Number(formData.investmentLength),
          Number(formData.initialDeposit)
        );
        setCalcResult(CI);

        // graph logic
        const futureInvestmentArray = () => {
          let arr = [];

          for (var i = 0; i < noOfYears; i++) {
            arr.push(
              calculateCompoundInterest(
                formData.investmentType,
                Number(formData.roi),
                Number(formData.investmentAmount),
                i + 1,
                Number(formData.initialDeposit)
              ).futureInvestment
            );
          }
          return arr;
        };
        const totalDepositArray = () => {
          let arr = [];

          for (var i = 0; i < noOfYears; i++) {
            arr.push(
              calculateCompoundInterest(
                formData.investmentType,
                Number(formData.roi),
                Number(formData.investmentAmount),
                i + 1,
                Number(formData.initialDeposit)
              ).totalDeposits
            );
          }
          return arr;
        };
        const totalInterestArray = () => {
          let arr = [];

          for (var i = 0; i < noOfYears; i++) {
            arr.push(
              calculateCompoundInterest(
                formData.investmentType,
                Number(formData.roi),
                Number(formData.investmentAmount),
                i + 1,
                Number(formData.initialDeposit)
              ).totalInterestEarned
            );
          }
          return arr;
        };
        setChartGroup({
          futureInvestment: futureInvestmentArray(),
          totalDeposits: totalDepositArray(),
          totalInterest: totalInterestArray(),
        });
        setShowChart(true);
      }
    } else {
      formSchema.validate(formData, { abortEarly: false }).catch((err) => {
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: true,
          };
        }, {});

        setErrors(errors);
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="relative bg-white py-16">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div className="grid lg:grid-cols-2 pb-3">
            <h2 className="font-roboto font-black text-3xl text-navy">
              Calculate your possible return on investments
            </h2>
          </div>
          <div className="border-t border-gray-4 py-8">
            <div className="grid lg:grid-cols-2 gap-14 lg:gap-4">
              <div className="w-full lg:w-4/5">
                <div>
                  <p className="text-gray-5 text-lg">
                    Answer a few questions and get a personalized investment
                    plan that grows your portfolio until it reaches your
                    possible savings target.
                  </p>

                  <div className="flex mt-4">
                    <span className="mr-3 text-green-2 text-lg font-light">
                      Start Calculating your target savings
                    </span>
                    <img src={arrowDown} alt="arrow-down-icon" />
                  </div>
                </div>
                <div>
                  <Form
                    handleSubmit={handleSubmit}
                    errors={errors}
                    formData={formData}
                    setFormData={setFormData}
                    additionalInvestmentField={additionalInvestmentField}
                  />
                </div>
              </div>

              <div className="hidden md:block border border-green-4 py-5 md:py-10 px-3 md:px-4 rounded-lg relative">
                <div className={`px-3 md:px-5 pb-2`}>
                  <div>
                    <p className="text-green-4 text-base md:text-xl font-bold mb-5">
                      Total Investment breakdown
                    </p>
                  </div>
                </div>
                {showChart ? (
                  <div>
                    <Chart
                      chartData={chartGroup}
                      calcResult={calcResult}
                      years={years}
                    />
                    <div className="bg-white shadow-lg rounded-lg w-full p-5 ml-auto">
                      <img src={infoIcon} alt="info-icon" />
                      <p className="mt-1 text-gray-3 font-light">
                        {interestTypeMessage}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Calculator;
