import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import { CTA } from "../../components/CTA/CTA";
import Footer from "../../components/Footer/Footer";
import StockTicker from "../../components/StockTicker/StockTicker";
import { TeamMember } from "../../components/Team/TeamMember";

import { EXECUTIVES } from "../../fixtures/about/teamInfo";
import coreValues from "../../fixtures/about/coreValues.json";
import stats from "../../fixtures/about/stats.json";

import AboutVision from "../../assets/img/mockups/about-vision.jpg";

const About = () => {
  return (
    <>
      <Navbar />
      <StockTicker />
      {/* Section */}
      <div
        className="relative bg-white py-16 sm:py-24 md:pt-2 md:pb-16"
        id="ceo-message"
      >
        <div 
        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={EXECUTIVES[0].imageUrl.image}
                  alt="The CEO, Eni, standing in front of a wall with a hanging painting"
                />
                <div className="absolute inset-0 bg-green-500 mix-blend-multiply opacity-10" />
                <div className="absolute inset-0 bg-gradient-to-t from-green-600 via-green-600 opacity-70" />
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative">
                        At Kamivest, our aim is to build a strategic investment
                        ecosystem that delivers professional and unrivalled
                        value across all asset classes to all our stakeholders.
                      </p>
                    </div>

                    <footer className="mt-4">
                      <p className="text-xl font-semibold text-green-200">
                        Eni Eniola, CEO
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                We’re empowering Africans
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-base leading-7">
                  We offer state-of-the-art trading technology and online
                  electronic brokerage services to both individuals and
                  institutional traders and investors.
                </p>
                <p className="text-base leading-7">
                  Our innovative trading, investment management and analysis
                  platform provides one-click access to all major global
                  exchanges and market centers. Our platform enables our client
                  to client to design, test, optimize, monitor and automate
                  their own custom equities, options and trading strategy.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {stats.map((stat) => (
                  <div
                    key={stat.label}
                    className="border-t-2 border-gray-100 pt-6"
                  >
                    <dt className="text-base font-medium text-gray-500">
                      {stat.label}
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-gray-900">
        <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
          <div className="h-full w-full xl:grid xl:grid-cols-2">
            <div className="h-full xl:relative xl:col-start-2">
              <img
                className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                src={AboutVision}
                alt="Man driving while scanning through his stock trading app"
              />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
              />
            </div>
          </div>
        </div>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
          <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
            <h2
              className="text-sm font-semibold tracking-wide uppercase"
              id="vision"
            >
              <span className="bg-gradient-to-r from-sky-300 to-blue-300 bg-clip-text text-transparent">
                Vision
              </span>
            </h2>
            <p className="mt-3 text-3xl font-extrabold text-white">
              Our vision is to build a financial ecosystem beneficial to
              everyone.
            </p>
            <h2
              className="text-sm font-semibold tracking-wide uppercase mt-5"
              id="mission"
            >
              <span className="bg-gradient-to-r from-sky-300 to-blue-300 bg-clip-text text-transparent">
                Mission
              </span>
            </h2>
            <p className="mt-3 text-3xl font-extrabold text-white">
              We have a mission to provide our investors with a transparent
              financial system where they can track as their investment grows
              consistently at an unprecedented rate within a short period of
              time.
            </p>
          </div>
        </div>
      </div>

      <div className="py-32 bg-white" id="core-values">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-green-500 sm:text-4xl">
              Core Values
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {coreValues.map((value) => (
                <div key={value.name} className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {value.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {value.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* Team */}
      <div className="bg-green-50" id="team">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                Meet our team
              </h2>
            </div>
            <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-4 lg:max-w-5xl">
              {EXECUTIVES.map((person) => (
                <li key={person.name}>
                  <TeamMember
                    name={person.name}
                    role={person.role}
                    imageUrl={person.imageUrl.image}
                    twitterUrl={person.twitterUrl}
                    linkedinUrl={person.linkedinUrl}
                    profileLink={person.profileLink}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </>
  );
};

export default About;
