import Select from "react-select";
import {
  productNameList,
  investmentTypeList,
} from "../../utils/constants/calculator";

import { customStyles } from "../Select/reactSelect";

export default function Form({
  formData,
  setFormData,
  additionalInvestmentField,
  errors,
  handleSubmit,
}) {
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSelectChange = (value, handler) => {
    setFormData({
      ...formData,
      [handler.name]: value,
    });
  };

  return (
    <form className="pt-10" onSubmit={handleSubmit}>
      <div className="mb-5">
        <label htmlFor="currency" className="text-gray-5 text-sm md:text-base">
          Currency
        </label>
        <div className="mt-1">
          <input
            name="currency"
            readOnly
            value={formData.currency}
            className="text-sm w-full h-10 px-3 py-3 focus:outline-none	tracking-wide border border-gray-7 rounded hover:border-green-4 focus:border-green-4"
          />
          {errors.currency ? (
            <span className="text-xs text-red-2">Field is required</span>
          ) : null}
        </div>
      </div>
      <div className="mb-5">
        <label
          htmlFor="productName"
          className="text-gray-5 text-sm md:text-base"
        >
          Product Name?
        </label>
        <div className="mt-1">
          <Select
            value={formData.productName}
            onChange={handleSelectChange}
            options={productNameList}
            styles={customStyles}
            name="productName"
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Please choose an option"
          />
          {errors.productName ? (
            <span className="text-xs text-red-2">Field is required</span>
          ) : null}
        </div>
      </div>

      <div className="mb-5">
        <label
          htmlFor="initialDeposit"
          className="text-gray-5 text-sm md:text-base"
        >
          Initial Deposit?
        </label>
        <div className="mt-1">
          <input
            type="number"
            name="initialDeposit"
            value={formData.initialDeposit}
            min={1}
            onChange={handleChange}
            className="text-sm w-full h-10 px-3 py-3 focus:outline-none	tracking-wide border border-gray-7 rounded hover:border-green-4 focus:border-green-4"
          />
          {errors.initialDeposit ? (
            <span className="text-xs text-red-2">Field is required</span>
          ) : null}
        </div>
      </div>

      {additionalInvestmentField ? (
        <div className="mb-5">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="Duration"
                className="text-gray-5 text-sm md:text-base"
              >
                How do you want to invest?
              </label>
              <div className="mt-2">
                <Select
                  defaultValue={formData.investmentType}
                  onChange={handleSelectChange}
                  options={investmentTypeList}
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  name="investmentType"
                  placeholder=""
                />
                {errors.investmentType ? (
                  <span className="text-xs text-red-2">Field is required</span>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor="investmentAmount"
                className="text-gray-5 text-sm md:text-base"
              >
                How much per{" "}
                {formData.investmentType.value === "monthly" ? `month` : `year`}
                ?
              </label>
              <div className="mt-2">
                <input
                  value={formData.investmentAmount}
                  onChange={handleChange}
                  name="investmentAmount"
                  type="number"
                  min={formData.investmentType.value === "monthly" ? 1 : 0}
                  className="text-sm w-full h-10 px-3 py-3 tracking-wide border border-gray-7 rounded hover:border-green-4 focus:border-green-4"
                />
                {errors.investmentAmount ? (
                  <span className="text-xs text-red-2">Field is required</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="mb-5">
        <label
          htmlFor="investmentLength"
          className="text-gray-5 text-sm md:text-base"
        >
          How long would you like your investment to run for? (Years)
        </label>
        <div className="mt-1">
          <input
            type="number"
            name="investmentLength"
            value={formData.investmentLength}
            min={1}
            onChange={handleChange}
            className="text-sm w-full h-10 px-3 py-3 focus:outline-none	tracking-wide border border-gray-7 rounded hover:border-green-4 focus:border-green-4"
          />
          {errors.investmentLength ? (
            <span className="text-xs text-red-2">Field is required</span>
          ) : null}
        </div>
      </div>

      <div className="mt-14">
        <button className="bg-green-4 font-bold text-white w-full h-10 md:h-12 rounded-lg md:text-xl">
          Calculate
        </button>
      </div>
    </form>
  );
}
