import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Index } from "./pages/mainsite/Index/Index";
import Home from "./pages/mainsite/Home/Home";
import About from "./pages/mainsite/About";
import { EniEniola } from "./pages/mainsite/team/Eni-Eniola";
import { TolaOsindero } from "./pages/mainsite/team/Tola-Osindero";
import { AnthonyTippa } from "./pages/mainsite/team/Anthony-Tippa";
import { AbiKamson } from "./pages/mainsite/team/Abi-Kamson";
import Services from "./pages/mainsite/Services";
import KamikoDIYTrading from "./pages/mainsite/products/KamikoDIYTrading";
import KamikoP2P from "./pages/mainsite/products/KamikoP2P";
import KamikoChopLife from "./pages/mainsite/products/KamikoChopLife";
import KamikoProGrowth from "./pages/mainsite/products/KamikoProGrowth";
import KamikoDollar from "./pages/mainsite/products/KamikoDollar";
import KamikoMonthly from "./pages/mainsite/products/KamikoMonthly";
import KamikoKredit from "./pages/mainsite/products/KamikoKredit";
import Calculator from "./pages/mainsite/Calculator";
import Contact from "./pages/mainsite/Contact";
import { News } from "./pages/mainsite/News";
import { FAQs } from "./pages/mainsite/FAQs";
import { PrivacyPolicy } from "./pages/mainsite/Privacy-Policy";
import NotFound from "./pages/NotFound";

import "@splidejs/splide/dist/css/splide.min.css";

function App() {
  // Tawk.to script
  useEffect(() => {
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/61eee8d9b9e4e21181bbac4d/1fq6jr59n";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={Index} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/about/team/eni-eniola" component={EniEniola} exact />
          <Route
            path="/about/team/tola-osindero"
            component={TolaOsindero}
            exact
          />
          <Route
            path="/about/team/anthony-tippa"
            component={AnthonyTippa}
            exact
          />
          <Route path="/about/team/abi-kamson" component={AbiKamson} exact />
          <Route path="/services" component={Services} exact />
          <Route
            path="/products/kamiko-diy-trading"
            component={KamikoDIYTrading}
            exact
          />
          <Route path="/products/kamiko-p2p" component={KamikoP2P} exact />
          <Route
            path="/products/kamiko-chop-life"
            component={KamikoChopLife}
            exact
          />
          <Route
            path="/products/kamiko-pro-growth"
            component={KamikoProGrowth}
            exact
          />
          <Route
            path="/products/kamiko-dollar"
            component={KamikoDollar}
            exact
          />
          <Route
            path="/products/kamiko-monthly"
            component={KamikoMonthly}
            exact
          />
          <Route
            path="/products/kamiko-kredit"
            component={KamikoKredit}
            exact
          />
          <Route path="/calculator" component={Calculator} exact />
          {/* <Route
            path="/calculators/simple-interest"
            component={SimpleInterestCalculator}
            exact
          />
          <Route
            path="/calculators/compound-interest"
            component={CompoundInterestCalculator}
            exact
          /> */}
          <Route path="/contact" component={Contact} exact />
          <Route path="/news" component={News} exact />
          <Route path="/frequently-asked-questions" component={FAQs} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />

          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
