import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export const Article = ({ post }) => {
	const imgPlaceholder =
		"https://source.unsplash.com/featured/1600x900/?business,trading";

	return (
		<>
			<div className='flex flex-col rounded-lg shadow-lg overflow-hidden'>
				<div className='flex-shrink-0'>
					<img
						className='h-48 w-full object-cover'
						src={post?.media === null ? imgPlaceholder : post?.media}
						alt={post?.title}
					/>
				</div>
				<div className='flex-1 bg-white p-6 flex flex-col justify-between'>
					<div className='flex-1'>
						<p className='text-sm font-medium text-indigo-600'>
							<Link
								to={{ pathname: post?.link }}
								className='hover:underline'
								target='_blank'
								rel='noreferrer'
							>
								{post?.author}
							</Link>
						</p>
						<Link
							to={{ pathname: post?.link }}
							className='block mt-2'
							target='_blank'
							rel='noreferrer'
						>
							<p className='text-xl font-semibold text-gray-900'>
								{post?.title}
							</p>
							<p className='mt-3 text-base text-gray-500 truncate'>
								{post?.summary}
							</p>
						</Link>
					</div>
					<div className='mt-6 flex items-center'>
						<div className='flex-shrink-0'>
							<span className='sr-only'>{post?.author}</span>
						</div>
						<div>
							<p className='text-sm font-medium text-gray-900'>
								{post?.authors === null ? `Writer Unavailable` : post?.authors}
							</p>
							<div className='flex space-x-1 text-sm text-gray-500'>
								<time
									dateTime={format(
										new Date(post?.published_date),
										"dd/MM/yyyy"
									)}
								>
									{format(new Date(post?.published_date), "dd/MM/yyyy")}
								</time>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
