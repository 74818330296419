import React from 'react';
import { Link } from 'react-router-dom';

export const ProductHero = ({ image, title, subtitle, primaryButton, secondaryButton }) => {
	return (
		<>
			<div className='relative'>
				<div className='absolute inset-x-0 bottom-0 h-1/2 bg-gray-100' />
				<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
					<div className='relative shadow-xl sm:rounded-2xl sm:overflow-hidden'>
						<div className='absolute inset-0'>
							<img
								className='h-full w-full object-cover'
								src={image}
								alt={`${title} cover`}
							/>
							<div className='absolute inset-0 bg-gradient-to-r from-green-800 to-green-600 mix-blend-multiply' />
						</div>
						<div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
							<h1 className='text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl'>
								<span className='block text-green-200'>{title}</span>
							</h1>
							<p className='mt-6 max-w-lg mx-auto text-center text-xl text-green-200 sm:max-w-3xl'>
								{subtitle}
							</p>
							<div className='mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center'>
								<div className='space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5'>
									<Link
										to='#'
										className='flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-green-700 bg-white hover:bg-green-50 sm:px-8'
									>
										{primaryButton}
									</Link>
									<Link
										to='#'
										className='flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8'
									>
										{secondaryButton}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
