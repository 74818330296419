export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "#000000"
      : state.isFocused
      ? "#000000"
      : "#495057",

    backgroundColor: state.isFocused ? "#edf2ff" : "white",
    padding: 15,
    fontFamily: "Roboto",
    fontSize: "0.875rem",
  }),
  control: (base, state) => ({
    ...base,
    height: "40px",
    borderRadius: state.isFocused ? "0.25rem" : "0.25rem",
    borderColor: state.isFocused ? "#58cba7" : "#E0E0E0",
    padding: "2.4px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#58cba7" : "#E0E0E0",
    },
    fontSize: "0.875rem",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#b3b3b3",
      fontSize: "#828282",
      fontWeight: 400,
    };
  },
};
