import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

export const PrivacyPolicy = () => {
	return (
		<>
			<Navbar />
			<div className='relative py-16 bg-white overflow-hidden'>
				<div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
					<div
						className='relative h-full text-lg max-w-prose mx-auto'
						aria-hidden='true'
					>
						<svg
							className='absolute top-12 left-full transform translate-x-32'
							width={404}
							height={384}
							fill='none'
							viewBox='0 0 404 384'
						>
							<defs>
								<pattern
									id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits='userSpaceOnUse'
								>
									<rect
										x={0}
										y={0}
										width={4}
										height={4}
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width={404}
								height={384}
								fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
							/>
						</svg>
						<svg
							className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
							width={404}
							height={384}
							fill='none'
							viewBox='0 0 404 384'
						>
							<defs>
								<pattern
									id='f210dbf6-a58d-4871-961e-36d5016a0f49'
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits='userSpaceOnUse'
								>
									<rect
										x={0}
										y={0}
										width={4}
										height={4}
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width={404}
								height={384}
								fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
							/>
						</svg>
						<svg
							className='absolute bottom-12 left-full transform translate-x-32'
							width={404}
							height={384}
							fill='none'
							viewBox='0 0 404 384'
						>
							<defs>
								<pattern
									id='d3eb07ae-5182-43e6-857d-35c643af9034'
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits='userSpaceOnUse'
								>
									<rect
										x={0}
										y={0}
										width={4}
										height={4}
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width={404}
								height={384}
								fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
							/>
						</svg>
					</div>
				</div>
				<div className='relative px-4 sm:px-6 lg:px-8'>
					<div className='text-lg max-w-prose mx-auto'>
						<h1>
							<span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
								Privacy Policy
							</span>
						</h1>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>Introduction</h2>
						<p>
							Kamivest recognises and upholds the privacy rights of its
							customers and will not breach these rights by disclosing, managing
							or exporting our customers’ personal information in a manner that
							is contrary to this Privacy Policy. This privacy policy explains
							the collection, purpose, use and sharing of personally information
							related to the use of Kamivest’s website, Kamivest’s services to
							our customers and our employees.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>The Information We Collect</h2>
						<p>
							Kamivest collects personal information through its interaction
							with you or through your interaction with our products. However,
							the way we collect your personal information depends on your
							privacy settings, products and features you choose to use. In
							certain circumstances, we obtain your personal information from
							third parties who subscribe to our products and services. It is
							advisable that you read the Privacy Policy of the product or
							service you are subscribed to for detailed information.
						</p>
						<p>
							Your use of our products and services are exclusively your
							prerogatives and you reserve the right to either provide or
							decline in providing such personal information. In the event that
							you decline to provide such necessary personal information, you
							may not be able to use such product or services and in certain
							circumstances, an applicable feature of that product or service.
						</p>
						<p>
							Sometimes, we are required under certain legislation and
							regulation to obtain your personal information, if you choose to
							decline providing us with same, you will not be able to use our
							products and services especially in cases where your personal
							information is necessary for the consummation of a contract with
							Kamivest.
						</p>
						<p>
							Please be informed that in the circumstances where personal
							information needs to be obtained for an existing product, if you
							choose to decline, we shall have to suspend or cancel the
							provision of our products and services to you.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>
							How We Use your Personal Information
						</h2>
						<p>
							Kamivest collects personal information using forms, email and
							physical requests, cookies, JWT, web tokens. When you send email
							or other communications to us, we may retain those communications
							in order to process your inquiries, respond to your requests and
							improve our services. When you access Kamivest’s services, our
							servers automatically record information that your browser sends
							whenever you visit a website.
						</p>
						<p>We use the above-mentioned information for:</p>
						<ul>
							<li>
								Personalisation and suggestion of products to other
								customers/users;
							</li>
							<li>Improvement and development of our products;</li>
							<li>
								Provision of value-added services such as updating, securing,
								support and troubleshooting; which is inclusive in the service
								provided based on your request.
							</li>
						</ul>
						<p>
							Your personal information also assists in our business operation
							such as legal obligations, research development, workforce
							development and performance analysis. In achieving these purposes,
							your personal information is combined from those obtained from
							different contexts or those obtained from third parties in order
							to achieve a seamless, consistent and personalised experience
							which enables us to make informed business decisions and use for
							other legitimate purposes.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>
							Why We Share Your Personal Information
						</h2>
						<p>
							Kamivest shares your personal information upon obtaining your
							express consent, completion of any transaction and the provision
							of any product or services which you may have requested or
							authorised. Kamivest also shares personal information with
							Kamivest’s affiliates, investors and subsidiaries only when
							necessary and for the performance of a specific obligation or
							purpose.
						</p>
						<p>
							When necessary, Kamivest may also share personal information with
							vendors working on its behalf; with law enforcement officers or
							third party when required by law or in response to a valid court
							order or as part of a legal process; or when necessary for the
							protection of lives and properties; maintenance of product
							security; protection of customers and the protection of Kamivest
							and its customers; and to fulfil any obligation under a share
							acquisition arrangement.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>
							How to Access and Control Your Personal Data
						</h2>
						<p>
							Kamivest respects your freewill in how we use your data. You have
							a choice to control the use of your personal information that is
							in Kamivest’s custody, thereby exercising your right of access.
							Your choice of accessing your personal information is dependent
							upon the products or services for which you are subscribed. Your
							right of access enables you to determine and control the adverts
							you receive based on your interest and preferences; receiving of
							promotional emails, short messaging service, telephone calls and a
							choice to delete your account totally.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>Cookies</h2>
						<p>
							Cookies are small text files placed on devices to store data which
							can then be recalled by a web server by the domain which placed
							the cookies. Kamivest uses cookies and similar technologies for
							tracking your preferences and settings; enabling and providing you
							with interest-based advertising and analysing our products and
							services.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>Third Party Access</h2>
						<p>
							Kamivest only shares personal information with other companies or
							individuals in the following limited circumstances:
						</p>
						<ol>
							<li>We have your consent;</li>
							<li>
								We provide such information to other professional advisers or
								other trusted businesses or persons for the purpose of
								processing personal information on our behalf. We require that
								these parties agree to process such information based on our
								instructions and in compliance with this Privacy Policy and any
								other appropriate confidentiality and security measures;
							</li>
							<li>
								We have a good faith belief that access, use, preservation or
								disclosure of such information is reasonably necessary to (a)
								satisfy any applicable law, regulation, legal process or
								enforceable governmental request, (b) enforce applicable terms
								of service, including investigation of potential violations
								thereof, (c) detect, prevent, or otherwise address fraud,
								security or technical issues, or (d) protect against imminent
								harm to the rights, property or safety of Kamivest, its users or
								the public as required or permitted by law; and
							</li>
							<li>
								To fulfil a condition of any share acquisition arrangement
								between Kamivest and a third party.
							</li>
						</ol>
						<p>
							Kamivest is at all times, responsible for the security and
							appropriate use of that data as long as it remains with it.
						</p>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>Violation of Privacy</h2>
						<p>
							We have put in place procedures to deal with any suspected
							personal data breach and will notify you of any personal data
							breach and let you know the steps we have taken to remedy the
							breach and the security measures we have applied to render your
							personal data unintelligible.
						</p>
						<p>
							All suspected breach of personal data will be remedied within 1
							(one) month from the date of the report of the breach.
						</p>
						<p>
							If you know or suspect that a personal data breach has occurred,
							you should immediately contact the privacy team at Kamivest.
						</p>
						<p>
							Kamivest will not be responsible for any personal data breach
							which occurs as a result of:
						</p>
						<ol>
							<li>an event which is beyond the control of Kamivest;</li>
							<li>an act or threats of terrorism;</li>
							<li>
								an act of God (such as, but not limited to fires, pandemic,
								explosions, earthquakes, drought, tidal waves and floods) which
								compromises Kamivest’s data protection measures;
							</li>
							<li>
								war, hostilities (whether war be declared or not), invasion, act
								of foreign enemies, mobilisation, requisition, or embargo;
							</li>
							<li>
								rebellion, revolution, insurrection, or military or usurped
								power, or civil war which compromises Kamivest’s data protection
								measures;
							</li>
							<li>
								the transfer of your personal data to a third party on your
								instructions; and
							</li>
							<li>
								the use of your personal data by a third party designated by
								you.
							</li>
						</ol>
					</div>
					<div className='mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto'>
						<h2 className='text-center'>How to Contact Us</h2>
						<p>
							For any enquiries you have in relation to this Privacy Policy,
							please contact our Data Protection Officer at <b class="italic">support@kamivest.com</b>.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
