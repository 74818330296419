import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";

import arrow from "../../../assets/img/home/arrow-up-right.svg";

export default function Plans() {
  return (
    <div className="py-16 bg-light">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 sm:max-w-3xl lg:px-16 lg:max-w-7xl">
        <div className="text-center">
          <h2 className="text-navy text-xl font-semibold mb-3 font-roboto">
            TOP RATED PLANS
          </h2>
          <h3 className="text-3xl text-green-4 capitalize font-roboto font-bold">
            investment. technology. wealth creation
          </h3>
        </div>
        <div className="py-10 ">
          <Splide
            options={{
              type: "loop",
              perPage: 3,
              perMove: 2,
              gap: "2.3rem",
              arrows: false,
              pagination: true,
              autoplay: true,
              boolean: true,
              interval: 3000,
              breakpoints: {
                767: {
                  perPage: 1,
                  pagination: true,
                },
                1023: {
                  perPage: 2,
                },
              },
            }}
          >
            {PRODUCT_INFO.map((product, index) => (
              <SplideSlide key={index} className="pb-3">
                <div className="bg-white plans_card h-full rounded-2xl flex flex-col">
                  <div className="card_img flex-shrink-0">
                    <img
                      src={product.image.image}
                      alt="product"
                      className="rounded-t-2xl h-52 w-full object-cover"
                    />
                  </div>
                  <div className="p-5 flex flex-1 flex-col justify-between">
                    <div className="card_text text-center">
                      <div>
                        <h3 className="text-navy text-lg font-roboto font-normal">
                          {product.name}
                        </h3>

                        <p className="pt-5 text-sm text-gray-3">
                          {product.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-8">
                      <Link
                        to={product.getStarted}
                        className="text-green-3 font-semibold"
                      >
                        Get Started
                      </Link>
                      <Link
                        className="flex items-center text-navy font-semibold"
                        to={product.learnMore}
                      >
                        Learn More
                        <img src={arrow} alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
}
