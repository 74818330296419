import React, { Fragment } from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<>
			<Fragment>
				<label
					htmlFor={field.name}
					className='block text-sm font-medium text-warm-gray-900'
				>
					{label}
				</label>
				<div className='mt-1'>
					<input
						{...field}
						{...props}
						className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 rounded-md ${
							meta.touched &&
							meta.error &&
							`border-red-500 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500`
						}`}
					/>
				</div>
				<small className='text-red-500'>
					<ErrorMessage name={field.name} />
				</small>
			</Fragment>
		</>
	);
};
