import DIYimg from "../../assets/img/mockups/services-trading.jpg";
import p2pImg from "../../assets/img/products/P2P.avif";
import ChopLifeImg from "../../assets/img/mockups/chop-life-hero.jpg";
import KamikoProGrowthImg from "../../assets/img/mockups/services-trading-two.jpg";
import KamikoDollarImg from "../../assets/img/products/dollar.jpg";
import KamikoMonthlyImg from "../../assets/img/products/diy-hero.jpg";
import KamikoKreditImg from "../../assets/img/mockups/kredit-hero.jpg";

export const PRODUCT_INFO = [
	{
		name: "Kamiko DIY Trading",
		image: { image: DIYimg },
		description: "We give you the tools. You take it from there.",
		learnMore: "/products/kamiko-diy-trading",
		getStarted: "#",
	},
	{
		name: "Kamiko P2P",
		image: { image: p2pImg },
		description: "A reliable way to swap currencies at the best rates.",
		learnMore: "/products/kamiko-p2p",
		getStarted: "#",
	},
	{
		name: "Kamiko Chop Life",
		image: { image: ChopLifeImg },
		description: "Save towards your goals.",
		learnMore: "/products/kamiko-chop-life",
		getStarted: "#",
	},
	{
		name: "Kamiko Pro Growth",
		image: { image: KamikoProGrowthImg },
		description: "Let us help you achieve your financial ambitions.",
		learnMore: "/products/kamiko-pro-growth",
		getStarted: "#",
	},
	{
		name: "Kamiko Dollar",
		image: { image: KamikoDollarImg },
		description:
			"Save and grow your Naira in Dollars to counter unstable inflation.",
		learnMore: "/products/kamiko-dollar",
		getStarted: "#",
	},
	{
		name: "Kamiko Monthly",
		image: { image: KamikoMonthlyImg },
		description: "We invest in the right places for you.",
		learnMore: "/products/kamiko-monthly",
		getStarted: "#",
	},
	{
		name: "Kamiko Kredit",
		image: { image: KamikoKreditImg },
		description:
			"Apply for up to double the capital you have already invested in any of our products.",
		learnMore: "/products/kamiko-kredit",
		getStarted: "#",
	},
];
