import React from "react";

import CTAmockup from "../../assets/img/mockups/cta-phone.png";
import appleLogo from "../../assets/img/logo/apple.svg";
import googleLogo from "../../assets/img/logo/google.svg";

export const CTA = () => {
  return (
    <>
      <div className="bg-white pt-24 md:pt-32 lg:pt-48 pb-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-16 lg:max-w-7xl">
          <div className="relative dots-bg bg-green-3 flex items-center justify-center w-full h-full rounded-3xl lg:h-100 px-5 md:px-10 lg:pr-12 lg:pl-0 pt-10 md:pt-16 lg:pt-0 lg:py-0">
            <div className="grid lg:grid-cols-2 gap-8">
              <div className="order-2 lg:order-1">
                <img
                  className="block lg:hidden"
                  src={CTAmockup}
                  alt="A mockup of the product on a phone"
                />
                <img
                  className="absolute cta_image bottom-0 hidden lg:block"
                  src={CTAmockup}
                  alt="A mockup of the product on a phone"
                />
              </div>

              <div className="order-1 lg:order-2">
                <div className="text-white font-roboto font-black capitalize text-xl md:text-3xl lg:text-4xl pb-3 md:pb-5">
                  Ready to start creating wealth?
                </div>
                <div>
                  <p className="text-navy font-bold text-sm md:text-lg lg:text-xl">
                    Get access to the best investment and savings platform from
                    the comfort of your mobile device.
                  </p>
                </div>

                <div className="pt-8 md:flex">
                  <button className="flex rounded-lg bg-gray-5 px-4 py-3 mr-5 w-52 text-white text-base md:text-lg leading-6 shadow-md">
                    <img src={appleLogo} alt="apple logo" />

                    <span className="ml-3 text-left leading-4">
                      <span className="text-sm md:text-base">Download on</span>
                      <br />
                      <span className="font-semibold">the App Store</span>
                    </span>
                  </button>
                  <button className="flex rounded-lg bg-gray-5 px-4 py-3 w-52 text-white text-base md:text-lg leading-6 shadow-md mt-5 md:mt-0">
                    <img src={googleLogo} alt="apple logo" />

                    <span className="ml-3 text-left leading-4">
                      <span className="text-sm md:text-base">GET IT ON</span>
                      <br />
                      <span className="font-semibold">Google Play</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
