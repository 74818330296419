import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { ProductHero } from "../../../components/Products/ProductHero/ProductHero";

import Naira from "../../../assets/img/products/naira.webp";

const features = [
	{
		name: "Stocks",
		subtitle:
			"We help you invest and manage your money into a portfolio of expertly selected US and Global stocks based on their long-term growth opportunities and quality earnings",
	},
	{
		name: "Real Estate",
		subtitle:
			"This medium-risk investment plan gives you access to a portfolio of high-demand rental properties across the country. Real estate is a necessity for all categories so the demand in this market will always be available. We have a dedicated market which continues to expand with profit running into millions.",
	},
	{
		name: "Agriculture",
		subtitle:
			"Agriculture will always present profitable opportunities. We invest and manage your funds in agricultural businesses that fill the gap within the agricultural ecosystem in Nigeria. We act as a broker to bring you profitable businesses in agriculture that yield high profits and quality returns.",
	},
];

const KamikoMonthly = () => {
	let location = useLocation();
	let history = useHistory();

	const productIndex = PRODUCT_INFO.findIndex(
		(product) => product.learnMore === location.pathname
	);

	const handleCalculatorRedirect = (event) => {
		event.preventDefault();
		history.push("/calculator");
		// history.push("/calculators/simple-interest");
	};

	return (
		<>
			<Navbar />
			<ProductHero
				image={PRODUCT_INFO[productIndex].image.image}
				title={PRODUCT_INFO[productIndex].name}
				subtitle={PRODUCT_INFO[productIndex].description}
				primaryButton='Join Waitlist'
				secondaryButton='Learn More'
			/>
			<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
				<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
					<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						We invest in the right places for you
					</p>
					<p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
						We analyze different markets and invest in the right places for you.
						If you’re looking to grow your funds in stocks, real estate and
						agriculture at the quickest possible time then this plan is for you.
						You will earn a monthly percentage on your capital.
					</p>
					<div className='mt-12'>
						<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
							{features.map((feature) => (
								<div key={feature.name} className='pt-6'>
									<div className='flow-root bg-gray-50 rounded-lg px-6 py-8'>
										<div className='-mt-6'>
											<h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>
												{feature.name}
											</h3>
											<p className='mt-5 text-base text-gray-500'>
												{feature.subtitle}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className='py-10'>
						<button
							type='button'
							className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none'
							onClick={handleCalculatorRedirect}
						>
							Calculate returns
						</button>
					</div>
				</div>
			</div>
			<div className='py-16 sm:py-24 lg:py-16 bg-gray-50'>
				<div class='min-w-screen animated fadeIn faster flex justify-center items-center outline-none focus:outline-none bg-no-repeat bg-center bg-cover'>
					<div class='relative flex flex-col items-center justify-center '>
						<div class='grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2'>
							<div class='flex flex-col'>
								<div class='bg-white shadow-md  rounded-3xl p-4'>
									<div class='flex-none lg:flex'>
										<div class='h-full w-full lg:h-48 lg:w-48 lg:mb-0 mb-3'>
											<img
												src={Naira}
												alt='Naira notes'
												class='w-full object-scale-down lg:object-cover lg:h-48 rounded-2xl'
											/>
										</div>
										<div class='flex-auto ml-3 justify-evenly py-2'>
											<div class='flex flex-wrap '>
												<div class='w-full flex-none text-xs text-blue-500 font-medium '>
													Kamiko Monthly Package
												</div>
												<h2 class='flex-auto text-xl font-bold'>Naira (NGN)</h2>
											</div>
											<div class='flex py-4 text-sm text-gray-500 space-x-2'>
												<div class='flex-1 flex-col inline-flex items-start'>
													<p className='text-sm'>Minimum deposit</p>
													<p className='text-lg font-medium'>&#x20A6;500,000</p>
												</div>
												<div class='flex-1 flex-col inline-flex items-start'>
													<p className='text-sm'>ROI: 1 year &amp; below</p>
													<p className='text-lg font-medium'>18%</p>
												</div>
											</div>
											<div class='flex text-sm text-gray-500'>
												<div class='flex-1 flex-col inline-flex items-start'>
													<p className='text-sm'>ROI: 1 year &amp; above</p>
													<p className='text-lg font-medium'>3%</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class='flex flex-col '>
								<div class='bg-white shadow-md  rounded-3xl p-4'>
									<div class='flex-none lg:flex'>
										<div class='h-full w-full lg:h-48 lg:w-48 lg:mb-0 mb-3'>
											<img
												src='https://source.unsplash.com/8lnbXtxFGZw'
												alt='Dollar notes'
												class='w-full object-scale-down lg:object-cover lg:h-48 rounded-2xl'
											/>
										</div>
										<div class='flex-auto ml-3 justify-evenly py-2'>
											<div class='flex flex-wrap '>
												<div class='w-full flex-none text-xs text-blue-500 font-medium '>
													Kamiko Monthly Package
												</div>
												<h2 class='flex-auto text-xl font-bold'>USD ($)</h2>
											</div>
											<div class='flex py-4 text-sm text-gray-500'>
												<div class='flex-1 flex-col inline-flex items-start'>
													<p className='text-sm'>Minimum deposit</p>
													<p className='text-lg font-medium'>$100</p>
												</div>
												<div class='flex-1 flex-col inline-flex items-start'>
													<p className='text-sm'>ROI</p>
													<p className='text-lg font-medium'>12%</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default KamikoMonthly;
