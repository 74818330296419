import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { TextField } from "../../components/InputGroup/TextField";
import { SelectField } from "../../components/InputGroup/SelectField";
import { TextAreaField } from "../../components/InputGroup/TextAreaField";
import {
	MailIcon,
	PhoneIcon,
	LocationMarkerIcon,
} from "@heroicons/react/outline";

import { PRODUCT_INFO } from "../../utils/constants/productInfo";

function getProductNames() {
	return PRODUCT_INFO.map((product) => product.name);
}

const Contact = () => {
	const [successMessage, setSuccessMessage] = useState(false);

	const showSuccessMessage = () => setSuccessMessage(true);

	const validate = Yup.object({
		first_name: Yup.string()
			.min(2, "Must be at least two characters")
			.required("First name is required")
			.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
		last_name: Yup.string()
			.min(2, "Must be at least two characters")
			.required("Last name is required")
			.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
		email: Yup.string().email("Email is invalid").required("Email is required"),
		phone: Yup.number().min(5).required("Phone number is required").integer(),
		products: Yup.string(),
		subject: Yup.string().required("Please give your message a title"),
		message: Yup.string().required("Please leave a message"),
	});

	return (
		<>
			<Navbar />
			<div className='min-h-screen bg-white'>
				<main className='overflow-hidden'>
					{/* Header */}
					<div className='bg-warm-gray-50'>
						<div className='py-12'>
							<div className='relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8'>
								<h1 className='text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl'>
									Get in touch
								</h1>
								<p className='mt-6 text-xl text-warm-gray-500 max-w-3xl'>
									We'd love to hear from you.
								</p>
							</div>
						</div>
					</div>

					{/* Contact section */}
					<section
						className='relative bg-white'
						aria-labelledby='contactHeading'
					>
						<div
							className='absolute w-full h-1/2 bg-warm-gray-50'
							aria-hidden='true'
						/>
						{/* Decorative dot pattern */}
						<div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
							<svg
								className='absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72'
								width={404}
								height={384}
								fill='none'
								viewBox='0 0 404 384'
								aria-hidden='true'
							>
								<defs>
									<pattern
										id='64e643ad-2176-4f86-b3d7-f2c5da3b6a6d'
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits='userSpaceOnUse'
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className='text-warm-gray-200'
											fill='currentColor'
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={384}
									fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
								/>
							</svg>
						</div>
						<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
							<div className='relative bg-green-400 shadow-xl'>
								<h2 id='contactHeading' className='sr-only'>
									Contact us
								</h2>

								<div className='grid grid-cols-1 lg:grid-cols-3'>
									{/* Contact information */}
									<div className='relative overflow-hidden py-10 px-6 bg-gradient-to-b from-teal-500 to-teal-600 sm:px-10 xl:p-12'>
										{/* Decorative angle backgrounds */}
										<div
											className='absolute inset-0 pointer-events-none sm:hidden'
											aria-hidden='true'
										>
											<svg
												className='absolute inset-0 w-full h-full'
												width={343}
												height={388}
												viewBox='0 0 343 388'
												fill='none'
												preserveAspectRatio='xMidYMid slice'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z'
													fill='url(#linear1)'
													fillOpacity='.1'
												/>
												<defs>
													<linearGradient
														id='linear1'
														x1='254.553'
														y1='107.554'
														x2='961.66'
														y2='814.66'
														gradientUnits='userSpaceOnUse'
													>
														<stop stopColor='#fff' />
														<stop offset={1} stopColor='#fff' stopOpacity={0} />
													</linearGradient>
												</defs>
											</svg>
										</div>
										<div
											className='hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden'
											aria-hidden='true'
										>
											<svg
												className='absolute inset-0 w-full h-full'
												width={359}
												height={339}
												viewBox='0 0 359 339'
												fill='none'
												preserveAspectRatio='xMidYMid slice'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z'
													fill='url(#linear2)'
													fillOpacity='.1'
												/>
												<defs>
													<linearGradient
														id='linear2'
														x1='192.553'
														y1='28.553'
														x2='899.66'
														y2='735.66'
														gradientUnits='userSpaceOnUse'
													>
														<stop stopColor='#fff' />
														<stop offset={1} stopColor='#fff' stopOpacity={0} />
													</linearGradient>
												</defs>
											</svg>
										</div>
										<div
											className='hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block'
											aria-hidden='true'
										>
											<svg
												className='absolute inset-0 w-full h-full'
												width={160}
												height={678}
												viewBox='0 0 160 678'
												fill='none'
												preserveAspectRatio='xMidYMid slice'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z'
													fill='url(#linear3)'
													fillOpacity='.1'
												/>
												<defs>
													<linearGradient
														id='linear3'
														x1='192.553'
														y1='325.553'
														x2='899.66'
														y2='1032.66'
														gradientUnits='userSpaceOnUse'
													>
														<stop stopColor='#fff' />
														<stop offset={1} stopColor='#fff' stopOpacity={0} />
													</linearGradient>
												</defs>
											</svg>
										</div>
										<h3 className='text-lg font-medium text-dark'>
											Contact information
										</h3>
										<p className='mt-6 text-base text-teal-50 max-w-3xl'>
											Here's how you can reach us.
										</p>
										<dl className='mt-8 space-y-6'>
											<dt>
												<span className='sr-only'>Phone number</span>
											</dt>
											<dd className='flex text-base text-teal-50'>
												<PhoneIcon
													className='flex-shrink-0 w-6 h-6 text-teal-200'
													aria-hidden='true'
												/>
												<span className='ml-3'>+234 7067 263 080</span>
											</dd>
											<dt>
												<span className='sr-only'>Email</span>
											</dt>
											<dd className='flex text-base text-teal-50'>
												<MailIcon
													className='flex-shrink-0 w-6 h-6 text-teal-200'
													aria-hidden='true'
												/>
												<span className='ml-3'>support@kamivest.com</span>
											</dd>
											<dt>
												<span className='sr-only'>Address</span>
											</dt>
											<dd className='flex text-base text-teal-50'>
												<LocationMarkerIcon
													className='flex-shrink-0 w-6 h-6 text-teal-200'
													aria-hidden='true'
												/>
												<span className='ml-3'>
													4A Opeyemisi Bamidele Crescent, Off Freedom way, Lekki
													Phase 1, Lagos Nigeria
												</span>
											</dd>
										</dl>
										<ul className='mt-8 flex space-x-12'>
											<li>
												<Link
													className='text-teal-200 hover:text-teal-100'
													to='#'
												>
													<span className='sr-only'>Facebook</span>
													<svg
														className='w-7 h-7'
														aria-hidden='true'
														fill='currentColor'
														viewBox='0 0 24 24'
													>
														<path
															fillRule='evenodd'
															d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
															clipRule='evenodd'
														/>
													</svg>
												</Link>
											</li>
											<li>
												<Link
													className='text-teal-200 hover:text-teal-100'
													to='#'
												>
													<span className='sr-only'>Twitter</span>
													<svg
														className='w-7 h-7'
														aria-hidden='true'
														fill='currentColor'
														viewBox='0 0 24 24'
													>
														<path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
													</svg>
												</Link>
											</li>
										</ul>
									</div>

									{/* Contact form */}
									<div className='py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12'>
										<h3 className='text-lg font-medium text-warm-gray-900'>
											Send us a message
										</h3>
										<Formik
											initialValues={{
												first_name: "",
												last_name: "",
												email: "",
												phone: "",
												products: `${PRODUCT_INFO[0]}`,
												subject: "",
												message: "",
											}}
											validationSchema={validate}
											onSubmit={(values, { resetForm }) => {
												resetForm();
												showSuccessMessage();
											}}
										>
											{(formik) => (
												<>
													<Form className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
														<div>
															<TextField
																label='First Name'
																type='text'
																name='first_name'
																id='first_name'
																autoComplete='given-name'
															/>
														</div>
														<div>
															<TextField
																label='Last Name'
																type='text'
																name='last_name'
																id='last_name'
																autoComplete='family-name'
															/>
														</div>
														<div>
															<TextField
																label='Email'
																id='email'
																name='email'
																type='email'
																autoComplete='email'
															/>
														</div>
														<div>
															<TextField
																label='Phone'
																type='tel'
																name='phone'
																id='phone'
																autoComplete='tel'
																aria-describedby='phone-optional'
															/>
														</div>
														<div className='sm:col-span-2'>
															<SelectField
																label='Products'
																id='products'
																name='products'
																products={getProductNames()}
																autoComplete='products'
															/>
														</div>
														<div className='sm:col-span-2'>
															<TextField
																label='Subject'
																type='text'
																name='subject'
																id='subject'
															/>
														</div>
														<div className='sm:col-span-2'>
															<TextAreaField
																label='Message'
																id='message'
																name='message'
																rows={4}
																aria-describedby='message-max'
															/>
														</div>
														{successMessage && (
															<p>
																<b>Message was successfully sent</b>
															</p>
														)}
														<div className='sm:col-span-2 sm:flex sm:justify-end'>
															<button
																type='submit'
																className='mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-black hover:text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto'
															>
																Submit
															</button>
														</div>
													</Form>
												</>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
};

export default Contact;
