import React from "react";
import { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";

import { howItWorks } from "../../../fixtures/home/howItWorks";

import Navbar from "../../../components/Navbar/Navbar.jsx";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import StockTicker from "../../../components/StockTicker/StockTicker";
import WhyKamivest from "../../../components/Home/WhyKamivest/WhyKamivest";
import Security from "../../../components/Home/Security/Security";
import Process from "../../../components/Home/Process/Process";
import DIY from "../../../components/Home/DIY/DIY";
import Plans from "../../../components/Home/Plans/Plans";

import HomeHeroImg from "../../../assets/img/home/girl_phone.svg";
import HomeHeroGreenCircleImg from "../../../assets/img/home/green_circle.svg";
import PiggyBankImg from "../../../assets/img/home/piggy_bank.svg";
import InvestChartImg from "../../../assets/img/home/invest_chart.svg";
import GooglePlayIcon from "../../../assets/img/icons/google-play.svg";
import AppleStoreIcon from "../../../assets/img/icons/apple-store.svg";

import "./Home.css";


const Home = () => {
  return (
    <>
      <div>
        <Toaster />
      </div>
      <Navbar />
      <StockTicker />
      <main className="max-w-7xl mx-auto my-16 px-4 sm:px-6 pb-10">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1 className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl text-blue-500">
              Investment and Savings platform for{" "}
              <span className="text-green-500">creating wealth</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              With our innovative platform, Kamivest provides you access to the
              best investment opportunities in the world helping you save,
              invest and create wealth from anywhere you are with ease!
            </p>
            <div className="flex justify-start items-center space-x-4 mt-10">
              <a
                href="https://zfrmz.com/ZfJhFZ6r54oTZq0ZmSil"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600"
                >
                  Join the Waitlist
                </button>
              </a>
              <a
                href="https://zfrmz.com/ZfJhFZ6r54oTZq0ZmSil"
                target="_blank"
                rel="noreferrer"
              >
                <button className="rounded-full w-12 h-12 flex items-center justify-center border border-solid border-gray-400 hover:bg-green-50">
                  <img src={GooglePlayIcon} alt="" className="px-1" />
                </button>
              </a>
              <a
                href="https://zfrmz.com/ZfJhFZ6r54oTZq0ZmSil"
                target="_blank"
                rel="noreferrer"
              >
                <button className="rounded-full w-12 h-12 flex items-center justify-center border border-solid border-gray-400 hover:bg-blue-50">
                  <img src={AppleStoreIcon} alt="" />
                </button>
              </a>
            </div>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div
              className="relative mx-auto grid hero-image__container"
              width={390}
            >
              <motion.img
                src={HomeHeroImg}
                alt=""
                initial={{ y: "-200vh" }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 100, delay: 0.9 }}
                className="hero-image__layer1 z-10 absolute right-3.5 -top-3.5"
              />
              <motion.img
                src={HomeHeroGreenCircleImg}
                alt=""
                className="hero-image__layer2"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ ease: "easeOut", duration: 2, delay: 1.9 }}
              />
              <motion.img
                src={PiggyBankImg}
                alt=""
                className="hero-image__layer2 h-36 absolute inset-y-0 left-0"
                initial={{ opacity: 0, scale: 0, x: "10vw" }}
                animate={{ opacity: 1, scale: 1, x: 0 }}
                transition={{ ease: "easeOut", duration: 2, delay: 1.9 }}
              />
              <motion.img
                src={InvestChartImg}
                alt=""
                className="hero-image__layer2 h-48 absolute inset-y-0 right-1.5 -top-7"
                initial={{ opacity: 0, scale: 0, x: "-10vw" }}
                animate={{ opacity: 1, scale: 1, x: 0 }}
                transition={{ ease: "easeOut", duration: 2, delay: 1.9 }}
              />
            </div>
          </div>
        </div>
      </main>

      <WhyKamivest />
      <div className="relative bg-white py-16 overflow-hidden lg:py-24">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
            Global Investments made easy
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-green-500 tracking-tight sm:text-4xl">
            How it Works
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {howItWorks.map((item) => (
                <div key={item.name} className="pt-6">
                  <div className="flow-root bg-white rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div className="inline-flex items-center justify-center h-14 w-14">
                        <img src={item.icon.image} alt={item.name} />
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {item.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Security />
      <Process />
      <DIY />
      <Plans />

      <CTA />
      <Footer />
    </>
  );
};

export default Home;
