import React from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { ProductHero } from "../../../components/Products/ProductHero/ProductHero";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";

const KamikoKredit = () => {
	let location = useLocation();

	const productIndex = PRODUCT_INFO.findIndex(
		(product) => product.learnMore === location.pathname
	);

	return (
		<>
			<Navbar />
			<ProductHero
				image={PRODUCT_INFO[productIndex].image.image}
				title={PRODUCT_INFO[productIndex].name}
				subtitle={PRODUCT_INFO[productIndex].description}
				primaryButton='Join Waitlist'
				secondaryButton='Learn More'
			/>
			<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
				<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
					<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						Buy now. Pay later.
					</p>
					<p className='mt-5 max-w-prose mx-auto text-left text-xl text-gray-500'>
						Looking for a better way to shop or pay for products on credit? With
						kamiko kredit, we help you make payments, all you have to do is
						choose a loan schedule that works for you and pay back bit by bit.
					</p>
					<p className='mt-5 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						Short term credit when you need it
					</p>
					<p className='mt-5 max-w-prose mx-auto text-left text-xl text-gray-500'>
						Apply for up to double the capital you have already invested in any
						of our products. We offer you access to short-term loans for your
						financial emergencies. With Kamiko Kredit, our clients are able to
						apply for and receive a loan of up to double the amount they have
						already invested on any of our products to be paid back typically
						within a duration of 12 months.
					</p>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default KamikoKredit;
