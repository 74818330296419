export const productNameList = [
  { value: "compound", label: "Target Savings", slug: "normal" },
  { value: "simple", label: "Kamiko Monthly", slug: "normal" },
  { value: "compound", label: "Moderate Investment", slug: "moderate" },
  { value: "compound", label: "Aggressive Investment", slug: "aggressive" },
];

export const investmentTypeList = [
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
];

export const currencyList = [
  { value: "naira", label: "Naira" },
  { value: "usd", label: "USD" },
];
