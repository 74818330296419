import phone from "../../../assets/img/home/diy_phone.png";

export default function DIY() {
  return (
    <div className="py-16 lg:pt-40 bg-white">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-16 lg:max-w-7xl">
        <div className="relative dots-bg bg-green-3 flex items-center justify-center w-full h-full rounded-3xl lg:h-100 px-5 md:px-10 lg:px-12 pt-10 md:pt-16 lg:pt-0 lg:py-0">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-0">
            <div>
              <div className="text-white font-roboto font-black capitalize text-xl md:text-3xl lg:text-4xl pb-3 md:pb-5">
                Try Kamiko DIY Trading
              </div>
              <div>
                <p className="text-white text-sm md:text-lg lg:text-xl">
                  Get the very best access to trade global stocks from the
                  comfort of your mobile device.
                </p>
              </div>
              <div className="pt-8">
                <button className="rounded-lg bg-white diy_button p-2 md:p-3 lg:w-32 mr-5 text-green-3 text-sm lg:text-lg font-bold">
                  Get Started
                </button>
                <button className="rounded-lg diy_button p-2 md:p-3 lg:w-32 border bg-green-3 border-white text-white text-sm lg:text-lg font-bold">
                  Learn More
                </button>
              </div>
            </div>
            <div>
              <img
                src={phone}
                alt="phone"
                className="block lg:hidden"
              />
              <img
                src={phone}
                alt="phone"
                className="hidden lg:block absolute bottom-0 diy_phone_image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
