import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { ProductHero } from "../../../components/Products/ProductHero/ProductHero";

import { PRODUCT_INFO } from "../../../utils/constants/productInfo";

const KamikoChopLife = () => {
	let location = useLocation();
	let history = useHistory();

	const productIndex = PRODUCT_INFO.findIndex(
		(product) => product.learnMore === location.pathname
	);

	const handleCalculatorRedirect = (event) => {
		event.preventDefault();
		history.push("/calculator");
		// history.push("/calculators/compound-interest");
	};

	return (
		<>
			<Navbar />
			<ProductHero
				image={PRODUCT_INFO[productIndex].image.image}
				title={PRODUCT_INFO[productIndex].name}
				subtitle={PRODUCT_INFO[productIndex].description}
				primaryButton='Join Waitlist'
				secondaryButton='Learn More'
			/>
			<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
				<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
					<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
						Save towards your goals.
					</p>
					<p className='mt-5 max-w-prose mx-auto text-left text-xl text-gray-500'>
						Achieve your long or short term financial targets with Kamiko Chop
						Life.
					</p>
					<p className='mt-5 max-w-prose mx-auto text-left text-xl text-gray-500'>
						Are you thinking of saving towards retirement, buying a house or
						sending your child to that Ivy League college? Kamiko Chop Life lets
						you set long or short term target savings towards achieving your
						dream while you earn good returns in dollars or naira.
					</p>
					<div className='py-10'>
						<button
							type='button'
							className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none'
							onClick={handleCalculatorRedirect}
						>
							Calculate returns
						</button>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};

export default KamikoChopLife;
