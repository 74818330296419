import React from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export const Pagination = ({ totalPages, activePage, page }) => {
	const pages = [...Array(totalPages).keys()].map(
		(pageNumber) => pageNumber + 1
	);

	return (
		<>
			<div className='bg-white px-4 py-3 my-4 flex items-center justify-between border-t border-gray-200 sm:px-6'>
				<div className='flex-1 flex justify-between sm:hidden'>
					<Link
						to='#'
						className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
					>
						Previous
					</Link>
					<Link
						to='#'
						className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
					>
						Next
					</Link>
				</div>
				<div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-center'>
					<div>
						<nav
							className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
							aria-label='Pagination'
						>
							<Link
								to='#'
								className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
							>
								<span className='sr-only'>Previous</span>
								<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
							</Link>
							{pages.map((pageNumber, index) => (
								<Link
									key={index}
									onClick={() => activePage(pageNumber)}
									to='#'
									aria-current='page'
									className={`${
										page === pageNumber
											? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
											: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
									} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
								>
									{pageNumber}
								</Link>
							))}
							<Link
								to='#'
								className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
							>
								<span className='sr-only'>Next</span>
								<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
							</Link>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};
