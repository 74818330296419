import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import { CTA } from "../../../components/CTA/CTA";
import Footer from "../../../components/Footer/Footer";
import { EXECUTIVES } from "../../../fixtures/about/teamInfo";

import ceoImg from "../../../assets/img/about/eni-ceo.jpg";

import { HomeIcon } from "@heroicons/react/solid";

const pages = [
	{ name: "Team", link: "/about/#team", current: false },
	{ name: EXECUTIVES[0].name, link: undefined, current: true },
];

export const EniEniola = () => {
	return (
		<>
			<Navbar />

			<nav
				className='bg-white border-b border-gray-200 flex'
				aria-label='Breadcrumb'
			>
				<ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
					<li className='flex'>
						<div className='flex items-center'>
							<Link to='/home' className='text-gray-400 hover:text-gray-500'>
								<HomeIcon
									className='flex-shrink-0 h-12 w-5'
									aria-hidden='true'
								/>
								<span className='sr-only'>Home</span>
							</Link>
						</div>
					</li>
					{pages.map((page) => (
						<li key={page.name} className='flex'>
							<div className='flex items-center'>
								<svg
									className='flex-shrink-0 h-5 w-5 text-gray-300'
									xmlns='http://www.w3.org/2000/svg'
									fill='currentColor'
									viewBox='0 0 20 20'
									aria-hidden='true'
								>
									<path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
								</svg>
								<Link
									to={page.link}
									className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
									aria-current={page.current ? "page" : undefined}
								>
									{page.name}
								</Link>
							</div>
						</li>
					))}
				</ol>
			</nav>

			<div className='bg-white overflow-hidden'>
				<div className='relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
					<div className='hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen' />
					<div className='mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none'>
						<div>
							<h2 className='text-base text-blue-600 font-semibold tracking-wide uppercase'>
								Executive Profile
							</h2>
							<h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
								{EXECUTIVES[0].name}
							</h3>
						</div>
					</div>
					<div className='mt-8 lg:grid lg:grid-cols-2 lg:gap-8'>
						<div className='relative lg:row-start-1 lg:col-start-2'>
							<svg
								className='hidden lg:block absolute top-0 right-0 -mt-20 -mr-20'
								width={404}
								height={384}
								fill='none'
								viewBox='0 0 404 384'
								aria-hidden='true'
							>
								<defs>
									<pattern
										id='de316486-4a29-4312-bdfc-fbce2132a2c1'
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits='userSpaceOnUse'
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className='text-gray-200'
											fill='currentColor'
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={384}
									fill='url(#de316486-4a29-4312-bdfc-fbce2132a2c1)'
								/>
							</svg>
							<div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
								<figure>
									<div className='aspect-w-12 aspect-h-7 lg:aspect-none'>
										<img
											className='rounded-lg shadow-lg object-cover object-center'
											src={ceoImg}
											alt='Whitney leaning against a railing on a downtown street'
											width={1184}
											height={1376}
										/>
									</div>
								</figure>
							</div>
						</div>
						<div className='mt-8 lg:mt-0'>
							<div className='text-base max-w-prose mx-auto lg:max-w-none'>
								<p className='text-lg text-gray-500'>
									Eni Eniola, M.sc, MPhil, MBA is the CEO and Co-Chief
									Investment Officer at Kamivest.
								</p>
							</div>
							<div className='mt-5 prose prose-blue text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1'>
								<p>
									He is tasked with responsibility of overseeing the business
									research, direction of investments and investment managers of
									the company including the real estate, fixed income, equity
									securities portfolios and general asset management
								</p>
								<p>
									Excelling in this field over the years, Eni has developed a
									sound understanding of investment policies and guidelines.
								</p>
								<p>
									Eni holds an MBA degree from Washington University in St.
									Louis, USA, Management and Strategy in Doing business in the
									emerging market from Harvard Business School, a PgDip in
									business development from Warwick Business School in England
									and MSc in information security from Royal Holloway University
									of London.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CTA />
			<Footer />
		</>
	);
};
