import React from "react";

import { Article } from "./Article";

import { ARTICLES_PER_PAGE } from "../../utils/constants/articlesPerPage";

export const Articles = ({ newsData, page }) => {
	const startIndex = (page - 1) * ARTICLES_PER_PAGE;
	const selectedArticle = newsData.slice(
		startIndex,
		startIndex + ARTICLES_PER_PAGE
	);

	return (
		<>
			<div className='mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none'>
				{selectedArticle.map((post) => (
					<Article post={post} key={post?.title}/>
				))}
			</div>
		</>
	);
};
