import secure from "../../../assets/img/home/secure.svg";

export default function Security() {
  return (
    <div className="security-bg py-16 md:py-28 px-4 md:px-8">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid lg:grid-cols-2 gap-5">
          <div className="bg-white rounded-3xl p-4 md:p-8">
            <div className="font-roboto text-light-green font-black text-lg md:text-3xl pb-3 md:pb-5">
              We keep your money safe
            </div>
            <div className="text-sm md:text-base text-navy">
              <p>
                Your security and trust are important to us. We're committed to
                protecting your account with the highest standards of security
                available. That is how we earn your trust
              </p>
            </div>

            <div className="pt-4 text-navy">
              <div className="md:text-lg font-semibold pb-2">
                Bank level security
              </div>

              <p className="text-sm md:text-base font-normal">
                We use state-of-the-art data encryption with SSL certificate
                when handling your financial information and Multi-factor
                authentication (MFA) protection. We're backed by top financial
                market operators and we not only meet traditional banking
                security standards, we exceed them.
              </p>
            </div>
            <div className="text-navy pt-5">
              <div className="md:text-lg font-semibold pb-2">
                Protected by NIG SEC and secured by US SEC
              </div>
              <p className="text-sm md:text-base font-normal">
                Accounts are held by our partner, a firm duly licensed by the
                Nigerian Securities and Exchange Commission and our U.S.
                brokerage partner is a member of the United States SIPC, which
                insures each user's account up to $500,000. Our licensing is
                provided by a partnership with VFD.
              </p>
            </div>
          </div>
          <div className="hidden md:grid md:order-first md:pb-6 lg:pb-0 lg:order-last">
            <img src={secure} alt="security" />
          </div>
        </div>
      </div>
    </div>
  );
}
